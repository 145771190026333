<template>
  <v-card class="rscope" dark>
    <v-card-title>
      <span class="fp mb-4" style="font-size: 15px">SZÁLLÍTÁSI CÍM</span>
      <v-btn class="close-card to-top-right" fab icon @click="close()"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-text-field
          :label="$t('store.deliveryAddress')"
          v-model="model.deliveryAddress"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          :label="$t('store.deliveryCity')"
          v-model="model.deliveryCity"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          :label="$t('store.zipCode')"
          v-model="model.deliveryZipCode"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->

        <v-text-field
          :label="$t('store.deliveryDistrict')"
          v-model="model.deliveryDistrict"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          :label="$t('store.deliveryFirstname')"
          v-model="model.deliveryFirstname"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          :label="$t('store.deliveryLastname')"
          v-model="model.deliveryLastname"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          :label="$t('store.deliveryPhoneNumber')"
          v-model="model.deliveryPhoneNumber"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
      </v-form>
      <v-btn x-large outlined rounded @click="submit()">Mentés</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import eventBus from "../eventBus.js";
import { fv } from "../util/functions.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE } from "../util/constants";
export default {
  props: ["valid", "isAddress"],
  data() {
    return {
      defaultRules: fv.default,
      emailRules: fv.emailRules,
      isValid: false,
      model: {
        deliveryAddress: null,
        deliveryCity: null,
        deliveryComment: null,
        deliveryDistrict: null,
        deliveryFirstname: null,
        deliveryLastname: null,
        deliveryPhoneNumber: null,
        deliveryZipCode: null,
      },
    };
  },
  mounted() {
    let obj2;
    this.$store.dispatch("getStore").then(() => {
      // console.log(event);
      obj2 = this.$store.getters.getStore;
      let a = Object.keys(this.model);
      a.forEach((item) => {
        this.model[item] = obj2[item];
      });
    });
  },
  methods: {
    close() {
      this.$emit("update:isAddress", false);
    },
    async submit() {
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "PUT",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(this.model),
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}client/stores/delivery-details`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          this.close();
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
  },
  watch: {
    isValid(val) {
      this.$emit("update:valid", val);
    },
  },
};
</script>

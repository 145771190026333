<template>
  <div class="CartTeaser">
    <v-card tile class="mr-4 ml-4 mb-8 teaser">
      <v-container fluid class="position-relative" style="overflow-x: hidden">
        <v-row dense>
          <v-col cols="4">
            <v-img
              class="mr-2 pa-2"
              width="130"
              :src="buildImage(item)"
            ></v-img>
          </v-col>
          <v-col>
            <small class="text-uppercase"
              ><b>{{ item.name }}</b></small
            >
            <div class="inline-spinner d-flex align-center">
              <v-btn
                icon
                outlined
                tile
                small
                color="primary"
                @click="decreaseCount()"
                ><v-icon small>mdi-minus</v-icon></v-btn
              >
              <v-avatar small color="white"
                ><span class="">{{ item.count }}</span></v-avatar
              >
              <v-btn
                icon
                outlined
                tile
                small
                color="primary"
                @click="increaseCount()"
                ><v-icon small>mdi-plus</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-btn @click="handleRemove()" class="to-top-right offset-50" fab small
          ><v-icon class="">mdi-close</v-icon></v-btn
        >
      </v-container>
    </v-card>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import { IMAGE_BASE } from "../util/constants.js";
export default {
  name: "Cart",
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    buildImage() {
      let path = this.item.productImages[0].dirPath.substring(1);
      let name = this.item.productImages[0].filename;
      return `${IMAGE_BASE}${path}${name}`;
    },
    increaseCount() {
      eventBus.$emit("REFRESH_CART");
      this.$store.dispatch("increaseCount", this.item.id);
    },
    decreaseCount() {
      eventBus.$emit("REFRESH_CART");
      this.$store.dispatch("decreaseCount", this.item.id);
    },
    handleRemove() {
      if (this.items.length > 1) {
        this.$store.dispatch("removeCartItem", this.item.id);
      } else {
        this.$store.dispatch("removeCartItem", this.item.id);
        this.$router.go(-1);
      }
      eventBus.$emit("REFRESH_CART");
    },
  },
  computed: {
    items() {
      return this.$store.getters.getCart;
    },
    user() {
      return this.$store.getters.getUser;
    },
    totalPoints() {
      return this.$store.getters.totalPoints;
    },
    cartTotal() {
      let total = 0;
      this.items.forEach((item) => {
        total += item.price;
      });
      return total;
    },
    remainingPoints() {
      return this.totalPoints - this.cartTotal;
    },
  },
};
</script>
<style scoped="">
.teaser {
  border-bottom: 3px solid var(--red);
  width: calc(100% - 40px);
}
</style>

<template>
  <div class="Cart">
    <v-dialog fullscreen v-model="isAddress" class="rscope">
      <delivery-address
        :valid.sync="isAddressValid"
        :is-address.sync="isAddress"
      ></delivery-address>
    </v-dialog>
    <v-dialog v-model="isOrderSuccessful" fullscreen>
      <v-card dark>
        <v-card-text class="order-message text-center">
          <v-sheet class="pa-8" color="#333">
            <v-avatar size="100" color="#000" class="mb-8"
              ><v-icon x-large>mdi-check</v-icon></v-avatar
            >
            <h2>Köszönjük!</h2>
            <p>Sikeresen leadtad rendelésed.</p>
            <v-btn @click="cleanup()" rounded outlined x-large dark class="fp"
              ><v-icon>mdi-arrow-left</v-icon>VISSZA</v-btn
            >
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card dark>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> SZÁLLÍTÁSI CÍM </v-list-item-title>
              <v-list-item-subtitle>{{ firstLine }}....</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="isAddress = true" fab small color="primary"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>RENDELÉS ÖSSZESEN:</v-list-item-content>
            <v-list-item-action>
              <v-avatar size="40" color="primary">
                {{ cartTotal }}
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              >Rendelkezésre álló pontjaid:</v-list-item-content
            >
            <v-list-item-action>
              {{ remainingPoints }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <cart-teaser v-for="item in items" :item="item" :key="item.id">
    </cart-teaser>
    <div class="bottom-bar-offset"></div>
    <div class="bottom-bar">
      <v-card tile dark>
        <v-card-text class="text-center pb-6">
          <v-btn
            :disabled="!isAddressValid"
            @click="submit()"
            class="fp"
            x-large
            outlined
            dark
            rounded
            >MEGRENDELEM <v-icon class="ml-2">mdi-check</v-icon></v-btn
          >
          <p v-if="!isAddressValid">
            <a href="#" @click.prevent="isAddress = true"
              >Ellenőrizze a szállítási címet</a
            >
          </p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE, IMAGE_BASE } from "../util/constants";
import CartTeaser from "../components/CartTeaser.vue";
import DeliveryAddress from "../components/DeliveryAddress.vue";

export default {
  name: "Cart",
  components: { CartTeaser, DeliveryAddress },
  data() {
    return {
      isAddress: false,
      isAddressValid: false,
      items: this.$store.getters.getCart,
      isOrderSuccessful: false,
    };
  },
  created() {
    eventBus.$on("REFRESH_CART", () => {
      setTimeout(() => {
        const store = JSON.parse(localStorage.getItem("vuex"));
        this.items = store.cart.items;
      }, 10);
    });
    eventBus.$emit("RIGHT_NAV", "CART");
  },
  methods: {
    buildImage(item) {
      let path = item.productImages[0].dirPath.substring(1);
      let name = item.productImages[0].filename;
      return `${IMAGE_BASE}${path}${name}`;
    },
    cleanup() {
      this.$store.dispatch("emptyCart");
      this.$store.dispatch("getStore");
      this.$router.push("/pont-bevaltas");
      this.isOrderSuccessful = false;
    },
    async submit() {
      eventBus.$emit("LOAD_ON");
      let data = [];

      this.items.forEach((item) => {
        let o = {
          productId: item.id,
          quantity: item.count,
        };
        data.push(o);
      });
      console.log(data);
      const requestOptions = {
        method: "POST",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(data),
        redirect: "follow",
      };
      try {
        let response = await fetch(`${API_BASE}client/orders`, requestOptions);

        if (response.ok) {
          const jsonResponse = await response.json();
          this.isOrderSuccessful = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
  },
  destroyed() {
    eventBus.$off("REFRESH_CART");
    eventBus.$emit("RIGHT_NAV", "DEFAULT");
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    totalPoints() {
      return this.$store.getters.totalPoints;
    },
    firstLine() {
      return this.$store.getters.getStore.deliveryAddress;
    },
    cartTotal() {
      let total = 0;
      this.items.forEach((item) => {
        total += item.price * item.count;
      });
      return total;
    },
    remainingPoints() {
      return this.totalPoints - this.cartTotal;
    },
  },
};
</script>
<style scoped>
.TextSearch {
  /*min-width: 100%;*/
}
.total {
  display: flex;
  align-items: center;
}
.bottom-bar {
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 0;
  right: 0;
}
.bottom-bar-offset {
  height: 120px;
}
.order-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.order-message .v-sheet {
  border-bottom: 3px solid var(--red) !important;
}
</style>
